import React, { useState, useEffect } from "react"
import styled from "styled-components"

import MinusIcon from "../../images/icons/Icon feather-minus-circle.svg"
import PlusIcon from "../../images/icons/Icon feather-plus-circle.svg"

import Chevron from "../../images/icons/chevron-right"

const FAQs = ({ faqs = [] }) => {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
  }, [])

  const topics = [...new Set(faqs.map((faq) => faq.topic))]

  const [selectedTopic, setSelectedTopic] = useState(0)

  const [faqState, setFaqState] = useState(faqs.map(() => false))

  const [mobileDropdownState, setMobileDropdownState] = useState("collapsed")

  const handleTopicSelect = () => {
    if (mobileDropdownState === "collapsed") {
      setMobileDropdownState("expanded")
    } else {
      setMobileDropdownState("collapsed")
    }
  }

  // state to force rerender because faqState doesn't for some reason
  const [rerender, setRerender] = useState(false)
  return faqs.length === 1 &&
    topics.length === 1 &&
    topics[0] === "Topic" ? null : (
    <Container>
      {windowWidth > 960 ? (
        <FAQTopicContainer>
          {topics.map((topic, index) => (
            <FAQTopic
              key={index}
              className={selectedTopic === index ? "selected" : ""}
              onClick={() => {
                setSelectedTopic(index)
              }}
            >
              {topic}
            </FAQTopic>
          ))}
        </FAQTopicContainer>
      ) : (
        <>
          <DropDown
            className={`${mobileDropdownState} ${"light"} ${"no-transform"}`}
            onClick={() => {
              handleTopicSelect()
            }}
          >
            {topics[selectedTopic]}
            <Chevron></Chevron>
          </DropDown>
          <DropDownMenu
            className={mobileDropdownState}
            height={topics.length * 60 + 2}
          >
            {topics.map((value, index) => {
              return (
                <MenuItem
                  className={`${
                    selectedTopic === index ? "selected" : ""
                  } ${"light"} ${"no-transform"}`}
                  onClick={() => {
                    setSelectedTopic(index)
                  }}
                  key={index}
                >
                  {value}
                </MenuItem>
              )
            })}
          </DropDownMenu>
        </>
      )}

      {faqs.map((faq, index) =>
        faq.topic === topics[selectedTopic] ? (
          <>
            <FAQTitleContainer
              onClick={() => {
                var x = faqState
                Object.assign(x, faqState)
                if (faqState[index] === true) {
                  x[index] = false
                } else {
                  x[index] = true
                }
                setRerender(!rerender)
                setFaqState(x)
              }}
            >
              <h4 className="light no-transform">{faq.title}</h4>
              {faqState[index] ? (
                <img src={MinusIcon} alt="icon" />
              ) : (
                <img src={PlusIcon} alt="icon" />
              )}
            </FAQTitleContainer>
            <FAQContentContainer className={faqState[index] ? "active" : ""}>
              <FAQContent>{faq.content}</FAQContent>
            </FAQContentContainer>
          </>
        ) : null
      )}
    </Container>
  )
}

export default FAQs

const Container = styled.div`
  max-width: 100%;
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 50px;
  flex-direction: column;
  margin-bottom: 100px;

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    margin-bottom: 50px;
  }
`

const FAQTopicContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 20px;
`

const FAQTopic = styled.h4`
  text-align: center;
  letter-spacing: 0px;
  cursor: pointer;

  &.selected {
    text-decoration: underline;
    letter-spacing: 0px;
  }
`

const FAQTitleContainer = styled.div`
  display: flex;
  background-color: white;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  margin: 10px 0;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.colors.brandGrey};
`

const FAQContentContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 10px 0;
  align-items: center;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s linear;

  &.active {
    max-height: 200px;
  }

  @media (max-width: ${({ theme }) => theme.tabletSmall}) {
    background-color: white;

    &.active {
      max-height: 400px;
    }
  }
`

const FAQContent = styled.p`
  text-align: left;
  margin: 0;
  margin-left: 20px;
`

const DropDown = styled.h5`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.brandGrey};
  border-radius: 1px;
  height: 60px;
  margin-top: 10px;
  align-items: center;
  position: relative;
  padding-left: 20px;

  svg {
    position: absolute;
    top: calc(50% - 5px);
    right: 20px;
    transform: rotate(90deg);
    transition: transform 0.3s ease-out;

    path {
      stroke: #112952;
    }
  }

  &.expanded {
    svg {
      transform: rotate(270deg);
    }
  }
`
const DropDownMenu = styled.div`
  width: 100%;
  height: fit-content;
  border-bottom: 1px solid #dddddd;
  max-height: ${({ height }) => height}px;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
  margin-bottom: 30px;

  &.collapsed {
    max-height: 0px;
    border: none;
  }
`
const MenuItem = styled.h5`
  display: flex;
  width: 100%;
  background-color: white;
  border-radius: 1px;
  height: 60px;
  align-items: center;
  padding-left: 20px;

  &.selected {
    background-color: ${({ theme }) => theme.colors.brandPrimary};
    color: white;
  }

  &.inactive {
    opacity: 0.3;
  }
`
