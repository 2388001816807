import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../theme/layout"
import SEO from "../components/header/seo"
import FAQs from "../components/faq/faqs"

const FAQPage = ({ pageContext, location }) => {
  const faqQueryData = useStaticQuery(FAQ_QUERY)
  const faqs = faqQueryData?.seaSiteInfo?.faqs || []

  return (
    <Layout
      pathContext={pageContext}
      location={location}
      bannerDetails={{
        title: faqQueryData.seaPage.title,
        description: faqQueryData.seaPage.description,
        image:
          faqQueryData.seaPage.featured_image.source_url,
      }}
    >
      <FAQs faqs={faqs}></FAQs>
      <SEO title={"FAQ"} location={location} yoast={faqQueryData.seaPage.seo} />
    </Layout>
  )
}

export default FAQPage

// ===============
//      QUERY
// ===============

const FAQ_QUERY = graphql`
  {
    seaPage(slug: { eq: "faq" }) {
      title
      description
      slug
      featured_image {
        source_url
      }
      seo {
        description
        og_description
        og_image
        og_title
        og_type
        site_name
        title
        twitter_card
        twitter_creator
        twitter_description
        twitter_title
      }
    }
    seaSiteInfo {
      faqs {
        title
        topic
        content
      }
    }
  }
`
